import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { TextField, Button } from "@material-ui/core"
import Layout from "../components/layout"
import ContactForm from "../components/ContactForm"

const SimpleContactForm = () => {
  return (
    <Layout>
      <Container fluid>
        <Row >
          <div class="col-12 col-lg-6 p-0  h-md-100" id="immobilien">
            <div class="logoarea pt-5 pb-5"></div>
          </div>

          <Col md={6} className="bg-white p-5 ">
            <Row>
              <ContactForm />
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default SimpleContactForm
